import React, { Component } from 'react'
import { Link } from 'gatsby'
import Video from 'react-player'

// Components Globals
import Header from 'components/header'
import Navigation from 'components/navigation-solutions'
import Footer from 'components/footer/main'
import Demo from 'components/s-demo/main'
import SEO from 'components/seo'

// Sass Global
import 'sass/main.scss'

import {
  BannerSolucao,
  TituloPrimario,
  TituloSecundario,
  SectionInformacoesGerais,
  SectionVideo,
  SectionFerramentas,
} from '../../Style/solucoes'

import { Container, TitleBanner } from '../../Style/global'

import IlustraGestaoPerformance from 'images/ilustracao-gestao-performance.svg'
import IlustracaoIndicadoresDesempenho from 'images/ilustracao-indicadores-desempenho.svg'
import IlustracaoDemoResultados from 'images/ilustracao-demo-resultados.svg'
import IlustracaoRelatoriosGerenciais from 'images/ilustracao-relatorios-gerenciais.svg'
import IconAnaliseVendas from 'images/icone-analise-vendas.svg'
import IconeGiroEstoque from 'images/icone-giro-estoque.svg'
import IconeRelatorioComissao from 'images/icone-relatorio-comissao.svg'
import IconeFechamentoMensagel from 'images/icone-fechamento-mensal.svg'
import IlustracaoFerramentas from 'images/ilustracao-ferramentas.svg'

class PageGestaoPerformance extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Gestão de Performance" />

        <Header />

        <Navigation from={this.props.location.pathname} />

        <BannerSolucao className="banner-gestao-performance">
          <Container className="flex">
            <div className="texto">
              <TitleBanner>Gestão de performance</TitleBanner>
              <h1>
                O que pode ser medido
                <br /> pode ser melhorado.
              </h1>
              <p>
                Tenha clareza dos resultados da sua farmácia<br></br> e planeje o melhor caminho a seguir.
              </p>
              <Link to="/sobre/contato">Solicite uma demonstração</Link>
            </div>
            <img src={IlustraGestaoPerformance} alt="Tela sistema Inovafarma gráficos, listagem e botões" className="ilustra" />
          </Container>
        </BannerSolucao>

        <SectionInformacoesGerais>
          <Container>
            <div className="item-texto">
              <img src={IlustracaoIndicadoresDesempenho} alt="Inovafarma Gráficos" className="ilustra" />
              <div className="texto">
                <TituloPrimario>
                  KPI - Indicadores de
                  <br /> desempenho
                </TituloPrimario>
                <p>
                  Veja os números da sua farmácia de forma estratégica, analisando os indicadores para uma tomada de decisão mais assertiva.
                </p>
                <p>O INOVAFARMA processa as informações do dia a dia da sua farmácia e apresenta em tempo real os resultados alcançados.</p>
                <Link to="/sobre/contato">Fale com um especialista</Link>
              </div>
            </div>
            <div className="item-texto">
              <img src={IlustracaoDemoResultados} alt="Inovafarma Gráficos" className="ilustra" />
              <div className="texto">
                <TituloPrimario>Demonstrativos de resultado</TituloPrimario>
                <p>
                  Mensure o faturamento e as despesas através da análise dos demonstrativos de resultado DLP, DFC e DRE garantindo a melhor
                  tomada de decisão estratégica.
                </p>
                <p>
                  O INOVAFARMA ajuda a entender o seu negócio através do monitoramento dos resultados contribuindo para alcançar a máxima
                  performance.
                </p>
                <Link to="/solucoes/">Saiba como</Link>
              </div>
            </div>
            <div className="item-texto">
              <img
                src={IlustracaoRelatoriosGerenciais}
                alt="Inovafarma relatórios fechamento mensal e vendas realizadas"
                className="ilustra"
              />
              <div className="texto">
                <TituloPrimario>Relatórios gerenciais</TituloPrimario>
                <p>
                  Melhore o controle operacional da farmácia através de relatórios gerenciais indicadores dinâmicos que mostram o cenário
                  atual do negócio.
                </p>
                <p>
                  São mais de 100 relatórios gerenciais disponíveis no INOVAFARMA para você acompanhar seus resultados de todas as
                  perspectivas.
                </p>
                <Link to="/solucoes/">Saiba como</Link>
              </div>
            </div>
          </Container>
        </SectionInformacoesGerais>

        <SectionFerramentas>
          <Container>
            <TituloPrimario>Ferramentas para acompanhar o seu negócio</TituloPrimario>
            <div className="texto">
              <img src={IlustracaoFerramentas} alt="Tela sistema Inovafarma gráficos, listagem e botões" className="ferramentas" />
              <ul>
                <li>
                  <img src={IconAnaliseVendas} alt="Icone busca" className="icone" />
                  <TituloSecundario>Análise de vendas</TituloSecundario>
                  <p>Acompanhe as categorias de produtos mais vendidos, ticket médio e o número de vendas da sua farmácia.</p>
                </li>
                <li>
                  <img src={IconeGiroEstoque} alt="Icone giro estoque" className="icone" />
                  <TituloSecundario>Giro de estoque</TituloSecundario>
                  <p>Monitore os produtos com maior e menor giro e evite a perda de medicamentos e ruptura de estoque.</p>
                </li>
                <li>
                  <img src={IconeRelatorioComissao} alt="Icone relatório" className="icone" />
                  <TituloSecundario>Relatório de comissão</TituloSecundario>
                  <p>Tenha sempre em mãos os resultados da sua equipe de forma rápida e assertiva.</p>
                </li>
                <li>
                  <img src={IconeFechamentoMensagel} alt="Icone fechamento mensal" className="icone" />
                  <TituloSecundario>Fechamento mensal</TituloSecundario>
                  <p>Entenda qual foi o resultado mensal da sua farmácia consolidando as principais informações gerenciais.</p>
                </li>
              </ul>
            </div>
          </Container>
        </SectionFerramentas>

        <SectionVideo className="bg-gray">
          <Container>
            <TituloPrimario>Veja como é simples fazer a gestão de compra e estoque da sua farmácia</TituloPrimario>
            <Video className="video video-gestao-performance" url="https://www.youtube.com/watch?v=fQhsU0_Iw4Y" light playing />
          </Container>
        </SectionVideo>

        <Demo />

        <Footer padding_maior={true} />
      </div>
    )
  }
}

export default PageGestaoPerformance
